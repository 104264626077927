<template>
    <div class="my-4">
        <div class="mb-4">
            <label for="dashboard-home-google" class="block mb-3 text-2xl font-bold">Обновить страницу в гугл</label>

            <div class="flex items-center gap-2">
                <input
                    id="dashboard-home-google"
                    v-model="url"
                    placeholder="Введите ссылку"
                    class="form-control form-control-lg"
                    type="text"
                />
                <LoadingIcon v-show="loading" icon="bars" color="white" class="block w-6 h-6" />
            </div>
        </div>

        <div class="flex gap-4">
            <button
                type="button"
                class="btn btn-primary w-30"
                :class="{ 'disabled-form': !url }"
                :disabled="!url"
                @click="updateGoogleIndex"
            >
                Обновить
            </button>

            <button
                type="button"
                class="btn btn-danger w-30"
                :class="{ 'disabled-form': !url }"
                :disabled="!url"
                @click="removeGoogleIndex"
            >
                Удалить
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GooglePageUpdate',
    data() {
        return {
            loading: false,
            url: null,
        };
    },
    methods: {
        async updateGoogleIndex() {
            this.validate();
            this.loading = true;

            try {
                await this.axios.post('/google-update', { url: this.url });
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant update: ', ex);
            }
        },
        async removeGoogleIndex() {
            this.validate();
            this.loading = true;

            try {
                await this.axios.post('/google-delete', { url: this.url });
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant update: ', ex);
            }
        },
        validate() {
            return !!this.url.value;
        },
    },
};
</script>

<style scoped>
.form-control {
    max-width: 640px;
}
</style>

<template>
    <div class="indexes-main">
        <h1 class="page__title">Индексация</h1>

        <GooglePageUpdate />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import GooglePageUpdate from '@/components/google-page-update/GooglePageUpdate';

export default defineComponent({
    components: { GooglePageUpdate },
    data() {
        return {};
    },
});
</script>
<style scoped lang="scss"></style>
